import React, {useEffect} from 'react';
import './App.css';
import {MessageDisplay, getMessageAsString} from "./MessageDisplay";
import {useSnackbar} from "notistack";
const urlParams = new URLSearchParams(window.location.search);
let eConnectSrc = 'app.php';
try {
    if (urlParams.has('object') && urlParams.has('objectId')) {
        const params: any = {};
        urlParams.forEach((value: any, key) => {
            params[key.toLowerCase()] = isNaN(value) ? value.toLowerCase() : value;
        });
        eConnectSrc = `app.php?object=${params.object}&objectid=${params.objectid}`;
        window.history.pushState({ additionalInformation: 'Updated the URL without reloading' }, document.title, window.location.origin)
    }
} catch(e) {
    console.error(e);
}

const App = ({authToken, getAuthToken}: {authToken: string | undefined | null, getAuthToken: (source?: any) => void}) => {
    const [isAuthorized, setIsAuthorized] = React.useState(false);
    const [sessionStarted, setSessionStarted] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [error, setError] = React.useState<string | null>(null);
    useEffect(() => {
        if(authToken && !isAuthorized) {
            setIsAuthorized(true)
            //enqueueSnackbar(`Authentication successful`, {variant: 'success'});
        }
    }, [authToken])
    useEffect(() => {
        const handleMessage = async (event: MessageEvent) => {
            if (event.data.event === 'eConnectUserSessionStarted') setSessionStarted(true);
            //Display error message
            if (event.data.event === 'serverError' || event.data.event === 'validationError') {
                //silently try to refresh the token
                await getAuthToken();
                enqueueSnackbar(getMessageAsString(event.data.status_code, event.data.message), {variant: 'error', autoHideDuration: 10000});
            }
            if (event.data.event === 'getAuthToken') await getAuthToken(event.source);
            if (event.data.event === 'authenticationError') setError(event.data.message);
        }
        // Add listener
        window.addEventListener('message', handleMessage);
        // Cleanup after ourselves when the component unmounts
        return () => window.removeEventListener('message', handleMessage);
    }, []);  // Note: the empty dependency list means this useEffect runs only on mount and unmount
    return (
      <React.Fragment>

          {error && <MessageDisplay type={999} reason={error} />}
          {!sessionStarted && !error && <MessageDisplay />}
          {isAuthorized && <iframe title={'eConnect'} id={'eConnectIframe'} src={eConnectSrc} style={{
              width: '100vw',
              height: '100vh',
              overflow: 'hidden',
              display: sessionStarted ? 'unset' : 'none'
          }}></iframe>
          }

      </React.Fragment>
  );
}

export default App;
