import {msalConfig, msalInstance} from './config';

/*
getIdToken was inspired by
https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md#acquiring-an-access-token-outside-of-a-react-component

 */
export const getIdToken = async () => {
  try {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
      throw new Error('User not logged in yet...');
    } else {
      const request = {
        scopes: [`${msalConfig.auth.clientId}/.default`],
        account: activeAccount || accounts[0]
      };

      const authResult = await msalInstance.acquireTokenSilent(request);
      // allow to refresh once if error
      localStorage.setItem('allow_refresh', 'true');
      return authResult.accessToken;
    }
  } catch (e: any) {
    console.error(e.toString());
    if (localStorage.getItem('allow_refresh') === 'true') {
      localStorage.removeItem('allow_refresh');
      window.location.reload();
    } else {
      window.postMessage({event: 'authenticationError', message: e.toString()}, window.location.origin);
        throw e;
    }
  }
  return undefined;
};
