import {PublicClientApplication} from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID!, // This is the ONLY mandatory field that you need to supply
    authority: process.env.REACT_APP_AZURE_AUTHORITY!, // Defaults to "https://login.microsoftonline.com/{TENANT_ID}",
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
   // redirectUri: 'http://localhost:3000', // Add this line
    navigateToLoginRequestUrl: true // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);