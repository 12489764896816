import { useEffect, useState } from 'react';
import {useAuth0} from "@auth0/auth0-react";

const useAuthorization = () => {
    const [authToken, setAuthToken] = useState<string | null | undefined>(null);
    const { getAccessTokenSilently } = useAuth0();
    const getAuthToken = async (source?: any) => {
        const token = await getAccessTokenSilently();
        try {
            if (source) source?.postMessage({event: 'tokenRefreshed', token});
        } catch (error: any) {
            console.error(error);
        }
        setAuthToken(token);
    }
    useEffect(() => {
        (async () => {
            await getAuthToken();
        })()
    }, []);

    return { authToken, getAuthToken };
};

export default useAuthorization;
