import React, {useEffect} from 'react';
import CloudIcon from '@mui/icons-material/Cloud';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import {ProviderWrapper as AzureProvider} from "./azure/ProviderWrapper";
import { ProviderWrapper as Auth0Wrapper } from "./auth0/ProviderWrapper";
import {msalInstance} from "./azure/config";

const LoginContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    padding: '20px',
});

const StyledCard = styled(Card)({
    minWidth: 320, // Adjusted the minimum width
    maxWidth: '95vw', // Added maximum width to handle smaller screens
    textAlign: 'center',
    padding: '20px',
    borderRadius: '16px', // Optional: added border radius for better aesthetics
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)', // Optional: added shadow for depth
});

const AuthButton = styled(Button)({
    margin: '10px 0',
    padding: '10px 0',
    width: '100%', // Ensuring the button stretches to the full width
    '&:hover': {
        opacity: 0.9,
    },
});

export const SelectAuthProvider = () => {
    const [authProvider, setAuthProvider] = React.useState<string | null>('init'); // You can change the type to 'azure' | 'auth0' if you want to restrict the value to those two options
    const [login, setLogin] = React.useState<boolean>(false);
    const handleAzureLogin = () => {
        (async () => {
            await msalInstance.initialize();
            setAuthProvider('azure');
            localStorage.setItem('authProvider', 'azure');
        })()
    };
    const handleAuth0Login = () => {
        setAuthProvider('auth0');
        localStorage.setItem('authProvider', 'auth0');
        setLogin(true);
        console.log("Auth0 login in progress");
    };

    useEffect(() => {
        //Fix to make sure that Tillotts users are logged in with Azure
        if (!localStorage.getItem('authProvider')) {
            if (localStorage.getItem('msal.account.keys')) localStorage.setItem('authProvider', 'azure');
        }
        (async () => {
            switch (localStorage.getItem('authProvider')) {
                case 'azure':
                    await msalInstance.initialize();
                    setAuthProvider('azure');
                    break;
                case 'auth0':
                    setAuthProvider('auth0');
                    break;
                default:
                    setAuthProvider(null);
                    break;
            }
        })()
    }, [])
    return (
        <React.Fragment>
        {
            !authProvider &&
            <LoginContainer>
                <StyledCard>
                    <CardContent>
                        <Typography variant="h4" component="div" gutterBottom>
                            eConnect Login
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Azure Login
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Recommended for Tillotts employees
                        </Typography>
                        <AuthButton
                            variant="contained"
                            color="primary"
                            onClick={handleAzureLogin}
                            startIcon={<CloudIcon />}
                        >
                            Log in with Azure
                        </AuthButton>
                        <Typography variant="subtitle1" gutterBottom paddingTop={2}>
                            Auth0 Login
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            For external users
                        </Typography>
                        <AuthButton
                            variant="contained"
                            color="secondary"
                            onClick={handleAuth0Login}
                            startIcon={<VpnKeyIcon />}
                        >
                            Log in with Auth0
                        </AuthButton>
                    </CardContent>
                </StyledCard>
            </LoginContainer>
        }
        {authProvider === 'azure' && <AzureProvider/>}
        {authProvider === 'auth0' && <Auth0Wrapper/>}
        </React.Fragment>
    );
};