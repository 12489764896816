import React from 'react';
import App from '../../App';
import {MsalAuthenticationTemplate, MsalProvider} from "@azure/msal-react";
import { msalInstance } from './config';
import {InteractionType} from "@azure/msal-browser";
import useAuthorization from "./useAuthorization";

const AzureInnerWrapper = () => {
    const {azureToken, getAuthToken} = useAuthorization();
    return (
        <App authToken={azureToken} getAuthToken={getAuthToken} />
    )
}
export const ProviderWrapper = () => {

        return (
            <React.StrictMode>
                <MsalProvider instance={msalInstance}>
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                        <AzureInnerWrapper />
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            </React.StrictMode>
        )
}

