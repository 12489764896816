import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {SelectAuthProvider} from "./authentication/SelectAuthProvider";
import { SnackbarProvider } from 'notistack';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          style={{width: '600px'}}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }}
      >
      <SelectAuthProvider/>
        </SnackbarProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
