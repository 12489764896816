import { useEffect, useState } from 'react';
import { getIdToken } from "./azureAuthProvider";

const useAuthorization = () => {
    const [azureToken, setAzureToken] = useState<string | null | undefined>(null);
    const getAuthToken = async (source?: any) => {
        const token = await getIdToken();
        try {
            if (source) source?.postMessage({event: 'tokenRefreshed', token});
        } catch (error: any) {
            console.error(error);
        }
        setAzureToken(token);
    }
    useEffect(() => {
        (async () => {
            await getAuthToken();
        })()
    }, []);

    return { azureToken, getAuthToken };
};

export default useAuthorization;
