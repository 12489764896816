import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {config} from "./config";
import App from "../../App";
import useAuthorization from "./useAuthorization";


const Auth0Wrapper = () => {
    const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();
    const { authToken, getAuthToken } = useAuthorization();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading, loginWithRedirect]);

    if (error) {
        // Hantera fel här, exempelvis logga till konsolen eller visa ett felmeddelande
        console.error('Auth0 Error:', error.message);
    }

    return (
        <App authToken={authToken} getAuthToken={getAuthToken} />
    );
}

export const ProviderWrapper = () => {
    return (
        <Auth0Provider {...config} onRedirectCallback={(appState, user) => console.log(appState, user)}>
                <Auth0Wrapper />
        </Auth0Provider>

    );

}
